import React from 'react'
import { HeaderFormWrap, ButtonWrap } from './search-form.style'
import Button from '../../../ui/button'


const HeaderForm = () => {

    return (
        <HeaderFormWrap>
                <ButtonWrap>
                    <Button 
                        to="/contact-us"
                        hover="2"
                    >
                        Inquire Now
                    </Button>
                </ButtonWrap>
        </HeaderFormWrap>
    )
}



export default HeaderForm
