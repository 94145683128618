import styled from 'styled-components';
import { device } from '../../../theme'

export const BoxIconInner = styled.div`
    padding: 30px;
    border-radius: 5px;
    transition: all 0.3s ease-in-out;
    @media ${device.medium}{
        padding: 20px;
    }
`;

export const BoxIconTop = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 10px;
`;

export const IconWrap = styled.div`
    flex-shrink: 0;
    font-size: 48px;
    min-width: 48px;
    margin-right: 20px;
    color: ${props => props.theme.colors.secondary};
    @media (prefers-color-scheme: dark) {
        color: ${props => props.theme.colorsDark.secondary};
    }
    height: 48px;
    display: inherit;
`;

export const Heading = styled.h5`
    color: ${props => props.theme.colors.themeColor};
    @media (prefers-color-scheme: dark) {
        color: ${props => props.theme.colorsDark.themeColor};
    }
`;

export const BoxIconBottom = styled.div``;

export const Text = styled.p``;

export const BoxIconWrap = styled.div`
    &:hover{
        ${BoxIconInner}{
            transform: translateY(-5px);
            @media (prefers-color-scheme: dark) {
                background: #131313;
            }
            background: #fff;
            box-shadow: 0 0 40px rgba(51,51,51,0.1);
        }
    }
`;