import styled from 'styled-components';
import { device } from '../../../../theme';

export const HeaderFormWrap = styled.div`
    height: 80%;
    // max-width: 300px;
    display: flex;
    align-items: center;
    background: transparent;
    position: relative;
   
    a {
        min-width: 180px;
        padding: 0 20px;
        font-size: 16px;
    }

    a:focus {
        color: #fff;
        outline: none;
        text-decoration: none;
      }
`;

export const ButtonWrap = styled.div`
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    @media ${device.xsmall}{
        right: 5px;
    }
`;