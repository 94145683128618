import React, { Fragment, useState, useEffect, useRef } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { useStaticQuery, graphql } from "gatsby"
import Logo from '../../../../components/logo'
import BurgerButton from '../../../../components/ui/burger-button'
import OffCanvas, { OffCanvasHeader, OffCanvasBody } from '../../../../components/ui/off-canvas';
import { MainMenu, MobileMenu } from '../../../../components/menu'
import HeaderForm from '../../../../components/forms/search-form/layout-four'
import {
    HeaderWrap,
    HeaderBottom,
    FixedHeader,
    HeaderMain,
    HeaderLeft,
    HeaderMiddle,
    HeaderRight,
    HeaderRightInner,
    HeaderNavigation,
    HeaderElement,
    FixedHeaderHeight
} from './header.style'

const Header = (props) => {
    const menuData = useStaticQuery(graphql`
        query MenuFourQuery {
            allMenuJson {
                edges {
                    node {
                        id
                        text
                        link
                        submenu {
                            link
                            text
                        }
                    }
                }
            }
        }
    `)
    const menuArr = menuData.allMenuJson.edges;
    const [offCanvasOpen, setOffcanvasOpen] = useState(false);
    const [headerInnerOpen] = useState(false);
    const [fixedHeaderHeight, setFixedHeaderHeight] = useState(0);
    const [totalHeaderHeight, setTotalHeaderHeight] = useState(0);
    const [sticky, setSticky] = useState(false);
    const headerRef = useRef(null);
    const fixedRef = useRef(null);
    const offCanvasHandler = () => {
        setOffcanvasOpen(prevState => !prevState);
    }

    useEffect(() => {
        setFixedHeaderHeight(fixedRef.current.clientHeight);
        setTotalHeaderHeight(headerRef.current.clientHeight);
    }, []);

    useEffect(() => {
        const scrollHandler = () => {
            let scrollPos = window.scrollY;
            if (scrollPos > totalHeaderHeight) {
                setSticky(true)
            }

            if (scrollPos < fixedHeaderHeight) {
                setSticky(false)
            }
        }

        window.addEventListener('scroll', scrollHandler);
        return () => {
            window.removeEventListener('scroll', scrollHandler);
        }
    }, [sticky, fixedHeaderHeight, totalHeaderHeight]);

    const { headerBottom } = props;
    const { logoStyle, burgerBtnElStyle } = headerBottom;

    return (
        <Fragment>
            <HeaderWrap ref={headerRef}>
                <HeaderBottom>
                    <FixedHeader ref={fixedRef} isSticky={sticky}>
                        <Container fluid>
                            <Row>
                                <Col lg={12}>
                                    <HeaderMain>
                                        <HeaderLeft>
                                            <Logo darkLogo {...logoStyle} />
                                        </HeaderLeft>
                                        <HeaderMiddle>
                                            <HeaderNavigation>
                                                <MainMenu menuData={menuArr} />
                                            </HeaderNavigation>
                                        </HeaderMiddle>
                                        <HeaderRight>
                                            <HeaderRightInner isOpen={headerInnerOpen}>
                                                <HeaderElement>
                                                    <HeaderForm />
                                                </HeaderElement>
                                            </HeaderRightInner>
                                            <HeaderElement
                                                {...burgerBtnElStyle}
                                                visibility={{ default: 'false', lg: 'true' }}
                                            >
                                                <BurgerButton onClick={offCanvasHandler} />
                                            </HeaderElement>
                                        </HeaderRight>
                                    </HeaderMain>
                                </Col>
                            </Row>
                        </Container>
                    </FixedHeader>
                    <FixedHeaderHeight height={fixedHeaderHeight} />
                </HeaderBottom>
            </HeaderWrap>
            <OffCanvas scrollable={true} isOpen={offCanvasOpen} onClick={offCanvasHandler}>
                <OffCanvasHeader onClick={offCanvasHandler}>
                    <Logo darkLogo align={{ default: 'flex-start' }} />
                </OffCanvasHeader>
                <OffCanvasBody>
                    <MobileMenu menuData={menuArr} />
                </OffCanvasBody>
            </OffCanvas>
        </Fragment>
    )
}



Header.defaultProps = {
    headerBottom: {
        logoStyle: {
            align: {
                default: 'center',
                lg: 'flex-start'
            }
        },
        langElStyle: {
            pr: '25px',
            responsive: {
                xlarge: {
                    pr: "10px"
                }
            }
        },
        headerFormElStyle: {
            bl: '0.5px solid rgba(128,128,128,0.1)',
            responsive: {
                xsmall: {
                    mt: '15px'
                }
            }
        },
        burgerBtnElStyle: {
            pl: "20px"
        },
        clickAbleElStyle: {
            pl: "15px"
        },
        clickAble: {
            fontsize: "20px",
            color: "#6D70A6"
        }
    }
}

export default Header
